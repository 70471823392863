'use client'

import {
  MeticulousProjectId,
  MeticulousSnippetSrc,
} from '@lib/meticulous/consts'
import { shouldShowMeticulousSnippet } from '@lib/meticulous/helpers/shouldShowMeticulousSnippet'
import { usePathname } from 'next/navigation'
import Script from 'next/script'

/**
 * Loads the meticulous snippet onto the page - this component should only be used in app router
 */
export default function MeticulousSnippet() {
  const pathName = usePathname()

  if (pathName && !shouldShowMeticulousSnippet(pathName)) {
    return null
  }

  return (
    <Script data-project-id={MeticulousProjectId} src={MeticulousSnippetSrc} />
  )
}
