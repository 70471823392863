import isProductionEnv from '@lib/utilities/isProductionEnv'

export function shouldShowMeticulousSnippet(path: string) {
  const supportedPathPrefixes = [
    '/about-us',
    '/about/',
    '/for-patients',
    '/for-research-coordinators',
    '/for-sponsors',
    '/founding-story',
    '/founding-story-update',
    '/murray-abramson-appointed-chief-medical-officer',
    '/p/',
    '/partners/',
    '/s/',
    '/sign-in',
    '/sign-up',
    '/white-papers/',
  ]

  const isSupportedPage = supportedPathPrefixes.some((prefix) =>
    path.startsWith(prefix),
  )
  const inProduction = isProductionEnv()
  // Show snippet for select production pages
  return isSupportedPage && inProduction
}
